import type { MouseEvent, FC, PropsWithChildren } from 'react';

import type { ThemeColorType } from '@capsule/consumer-design-system';

import { Typography } from '../Typography';

import { StyledLink, StyledMediumLink, StyledNavLink, StyledSimpleLink } from './styles';

interface LinkProps
  extends PropsWithChildren<{
    ariaLabel?: string;
    color?: ThemeColorType;
    dataCy?: string;
    href: string;
    onClick?: (e: MouseEvent) => void;
    small?: boolean;
    target?: '_blank';
  }> {}

export const Link: FC<LinkProps> = ({ ariaLabel, children, color, dataCy, href, onClick, target }) => {
  return (
    <Typography variant="body">
      <StyledLink
        aria-label={ariaLabel}
        href={href}
        data-cy={dataCy}
        onClick={onClick}
        color={color}
        target={target}
        rel={target ? 'noopener noreferrer' : undefined}
      >
        {children}
      </StyledLink>
    </Typography>
  );
};

export const MediumLink: FC<LinkProps> = ({ ariaLabel, children, dataCy, href, target, onClick }) => {
  return (
    <StyledMediumLink
      aria-label={ariaLabel}
      href={href}
      data-cy={dataCy}
      onClick={onClick}
      target={target}
      rel={target ? 'noopener noreferrer' : undefined}
    >
      {children}
    </StyledMediumLink>
  );
};

export const NavLink: FC<LinkProps> = ({ ariaLabel, children, color, dataCy, href, target, onClick }) => {
  return (
    <StyledNavLink
      aria-label={ariaLabel}
      href={href}
      data-cy={dataCy}
      onClick={onClick}
      color={color}
      target={target}
      rel={target ? 'noopener noreferrer' : undefined}
    >
      {children}
    </StyledNavLink>
  );
};

// TODO: link should be replaced with simple link. Shouldnt have Typography in the link component. Or should typography have a link variant
// Typography should have a link variant to keep all copy in Typography components
// Maybe not use a variant so we can have custom link props. Link should consume typography though
export const SimpleLink: FC<LinkProps> = ({ ariaLabel, children, color, dataCy, href, target, onClick, small }) => {
  return (
    <StyledSimpleLink
      aria-label={ariaLabel}
      href={href}
      data-cy={dataCy}
      {...{ onClick, color, small }}
      target={target}
      rel={target ? 'noopener noreferrer' : undefined}
    >
      {children}
    </StyledSimpleLink>
  );
};
