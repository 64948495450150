import type { FC, PropsWithChildren } from 'react';

import type { ThemeColorType } from '@capsule/consumer-design-system';

import { FlexCenterContainer, MaxWidthContainer } from './SectionContainerStyles';

export interface SectionContainerProps
  extends PropsWithChildren<{
    backgroundColor?: ThemeColorType;
    borderBottom?: boolean;
    hasPadding?: boolean;
    isOffsetForHeader?: boolean;
  }> {}

export const SectionContainer: FC<SectionContainerProps> = ({
  backgroundColor,
  borderBottom,
  children,
  hasPadding = true,
  isOffsetForHeader = false,
}) => {
  return (
    <FlexCenterContainer backgroundColor={backgroundColor} isOffsetForHeader={isOffsetForHeader}>
      <MaxWidthContainer borderBottom={borderBottom} hasPadding={hasPadding}>
        {children}
      </MaxWidthContainer>
    </FlexCenterContainer>
  );
};
