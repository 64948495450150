import type { ComponentType, FC, PropsWithChildren } from 'react';

import { useMediaQuery } from '@react-hookz/web';
import type { DefaultTheme, StyledComponent } from 'styled-components';

import { Breakpoint } from 'src/styles/theme';
import type { ThemeSpaceType } from 'src/styles/theme';

import {
  Body,
  Callout,
  CalloutSmall,
  DisplayXL,
  DisplayXXL,
  DisplayXXXL,
  H1,
  H2,
  H3,
  H4,
  Overline,
  Quote,
  Small,
} from './styles';

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'displayXXXL'
  | 'displayXXL'
  | 'displayXL'
  | 'body'
  | 'small'
  | 'overline'
  | 'callout'
  | 'calloutSmall'
  | 'quote';

export interface TypographyProps
  extends PropsWithChildren<{
    className?: string;
    color?: string;
    marginBottom?: ThemeSpaceType;
    marginTop?: ThemeSpaceType;
    /**
     * tag to force render typography as
     */
    as?: keyof JSX.IntrinsicElements;
    variant: TypographyVariant;
    /**
     * Media breakpoint small down will use this variant
     */
    smallDownVariant?: TypographyVariant;
    /**
     * Media breakpoint medium down will use this variant
     */
    mediumDownVariant?: TypographyVariant;
    /**
     * Media breakpoint large down will use this variant
     */
    largeDown?: TypographyVariant;
  }> {}

const variants = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  displayXXXL: DisplayXXXL,
  displayXXL: DisplayXXL,
  displayXL: DisplayXL,
  body: Body,
  small: Small,
  overline: Overline,
  callout: Callout,
  calloutSmall: CalloutSmall,
  // TODO is this in the design system?
  quote: Quote,
};

export const Typography: FC<TypographyProps> = ({
  children,
  className,
  color,
  marginBottom,
  marginTop,
  variant,
  smallDownVariant,
  mediumDownVariant,
  largeDown,
  as,
}) => {
  const smDownBreak = useMediaQuery(Breakpoint.smallDown);
  const mdDownBreak = useMediaQuery(Breakpoint.mediumDown);
  const lgDownBreak = useMediaQuery(Breakpoint.largeDown);

  let Component: StyledComponent<keyof JSX.IntrinsicElements | ComponentType<any>, DefaultTheme, object, keyof any>;

  if (smDownBreak && smallDownVariant) {
    Component = variants[smallDownVariant];
  } else if (mdDownBreak && mediumDownVariant) {
    Component = variants[mediumDownVariant];
  } else if (lgDownBreak && largeDown) {
    Component = variants[largeDown];
  } else {
    Component = variants[variant];
  }

  return (
    <Component as={as} className={className} color={color} marginBottom={marginBottom} marginTop={marginTop}>
      {children}
    </Component>
  );
};
