import type { FC, MouseEvent, ReactElement } from 'react';

import { AccordionItemState, AccordionItem, AccordionItemHeading } from 'react-accessible-accordion';

import type { ThemeSpaceType } from 'src/styles/theme';

import {
  AccordionArrow,
  AccordionItemButton,
  AccordionItemHeader,
  AccordionItemPanel,
  StyledAccessibleAccordion,
} from './styles';

type Question = {
  question: ReactElement<{ [key: string]: string }>;
  answer: ReactElement;
};

type AccordionProps = {
  questions: Question[];
  onExpand: (isExpanded: boolean, text: string, e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  hasInitialBorderTop?: boolean;
  marginBottom?: ThemeSpaceType;
};

export const Accordion: FC<AccordionProps> = ({ questions, onExpand, hasInitialBorderTop = true, marginBottom }) => {
  return (
    // Allowing multiple expanded will break analytics
    <StyledAccessibleAccordion allowZeroExpanded allowMultipleExpanded={false} $marginBottom={marginBottom}>
      {questions?.map((question, idx) => (
        <AccordionItem key={idx}>
          <AccordionItemState>
            {({ expanded }: { expanded: boolean }) => (
              <>
                <AccordionItemHeading onClick={e => onExpand(expanded, question.question.props.children, e)}>
                  <AccordionItemButton $hasInitialBorderTop={hasInitialBorderTop}>
                    <AccordionItemHeader>
                      <AccordionArrow
                        $expanded={expanded}
                        aria-expanded={`${expanded}`}
                        role="button"
                        aria-label={'Toggle accordion panel'}
                      />
                      {question.question}
                    </AccordionItemHeader>
                    <AccordionItemPanel className="accordion_answer" $expanded={expanded}>
                      {question.answer}
                    </AccordionItemPanel>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </>
            )}
          </AccordionItemState>
        </AccordionItem>
      ))}
    </StyledAccessibleAccordion>
  );
};
